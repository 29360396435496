.footer {
  background-color: #EFEFEF;
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 24px;
  padding-bottom: 36px;


  position: absolute;
  left:0;
  right: 0;
  bottom: 0;
  height: 210px;

  font-family: 'IBM Plex Sans';
  font-weight: 500;
  font-size: 14px;
  color: #4F4F4F;
  letter-spacing: 0.6px;
  a {
    &:visited{

      color: #4F4F4F;
    }
  }
}

.footer-title {
  font-weight: Bold;
  font-size: 14px;
  color: #4F4F4F;
  letter-spacing: 0.6px;
  padding-bottom: 10px;
}

.aia-logo {
  height: 40px;
  margin-bottom: -20px;
}
.other-logos {
  display: flex;
  align-items: center;
  padding-top: 10px;
}
.other-logo{
  height: 56px;
  padding-right:10px;
  opacity: 1;
}
.aia {
  height: 54px;
}

.contact-items * {
  margin-bottom: 10px;
}

.mobile-col {
  display: block;
} 
.wide-col {
  display: none;
} 

.mobile-2011 {
  display: none;
}
@media only screen and (max-width: 481px) {
  .mobile-col-sm {
    display: none;
  }
}

@media only screen and (min-width: 768px) {
  .mobile-2011 {
    display: block;
  }
  .aia-logo {
    display: none;
  }
}

@media only screen and (min-width: 992px) {
  .mobile-col {
    display: none;
  } 
  .wide-col {
    display: block;
  } 
  .top-contact {
    display: none;
  }
}

@media only screen and (min-width: 992px) {
  .top-contact {
    display: block;
  }
}