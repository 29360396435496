.swiper-container {
  //width: 500px;
}

.swiper-pagination {
  padding-bottom: 0px;
  bottom: 10px;
}



.image-wrap {
position: relative;
object-fit: contain;
  img {
    object-fit: contain;
  }
}

.dummy-image {
  background-color: var(--blue-2);
}

.image-behave img{
  object-fit: contain;
  width:100%; 
  height:100%;
}

.caption {
  position: absolute;
  bottom: 4px;

  color: white;

  padding-left: 10px;
  padding-bottom: 10px;

  background-image: linear-gradient(180deg, rgba(0,0,0,0.00) 0%, rgba(0, 0, 0, 0.5) 100%);
  
  width: 100%;
  font-size: 16px;
  letter-spacing: 0.03em;
  font-weight: 700;
}

.swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 0%;
}

.swiper-pagination-bullet-active {
  background-color: var(--green-0);
}

.swiper-wrapper {
  padding-inline-start: 0;
  margin-bottom: 0;
  margin-top: 0px;
}


.swiper-button-prev, .swiper-container-rtl .swiper-button-next {
  padding-right: 4px;
  margin-left: -10px;
  margin-right: 0px;
  right: auto;
  width: 90px;
  height: 100%;
  top: 0px;
  //background: linear-gradient(90deg, rgba(106, 109, 125, 0.9) 0%, rgba(106, 109, 125, 0) 100%);
  color: white;
  mix-blend-mode: screen;
  &:after {
    transform: scale(.8);
  }
}

.swiper-button-next, .swiper-container-rtl .swiper-button-prev {
  padding-right: 4px;
  padding-left: 10px;
  margin-right: 0px;
  right: 0px;
  width: 90px;
  height: 100%;
  top: 0px;
  //background: linear-gradient(270deg, rgba(106, 109, 125, 0.9) 0%, rgba(106, 109, 125, 0) 100%);
  color: white;
  mix-blend-mode: screen;
  &:after {
    transform: scale(.8);
  }
}
@media only screen and (min-width: 768px) {
  .caption-text{
    font-size: 16px;
    letter-spacing: 0.03em;
    text-transform: none;
    padding-right:50px;
  }
}
