
.about-body {
  min-height: 550px;
  background-color: var(--blue-0);
}
.sticky-profile {
  position: relative;

}

.grid-image-above {
  object-fit: contain;
  margin-top:20px;
  display: flex;
  align-items: center;
  height: auto;
  
  img {
    display: flex;
    //height: 570px;
    max-height: 560px;
    width: 100%;
    object-fit: contain;
  }
}


.temp-img {
  width: 300px;
  height: 300px;
  object-fit: contain;
  img {
    height: 300px;
    object-fit: contain;
    float: right;
  }
}
.profile-wrap{
  display: flex;
  flex-grow: 1;
  max-width: 820px;
}
.profile-box {

  overflow: hidden;
}

.profile-bio-block {
  max-width: 520px;
  line-height: 22px;
  font-size: 18px;
  letter-spacing: 0.006em;
}

.profile-description-h1 {
font-size: 30px;
font-weight: 600;
padding-top: 0;
margin-bottom: 0;
margin-top: 0;
}
.profile-description {

  h2 {
    padding-top: 0;
    margin-bottom: 0;
    margin-top: 0;
  }
  p {
    padding-top: 0;
    margin-bottom: 24px;
    margin-top: 0;
  }
}

.banner {
  // background-color: var(--blue-2);
  img {
    width: 100%;
    mix-blend-mode: luminosity;
    filter: saturate(0);
    opacity: 0.8;

    object-fit: cover;
  }
  height: 100px;
  object-fit: contain;
  overflow: hidden;
 
}
// copied from portfolio page styles

.about-page-back {
  background-color: var(--blue-0);

  min-height: 720px;
  position: relative;
}

.portfolio-section {
  position: relative;
}


.main-image {
  object-fit: cover;
  width: 100%;
  height: 230px;
  &:hover{
    img {outline:1px solid white;     }
  }


}
.project-card-wrap{
  display: flex;
  flex-direction: column;
}
.card-img {
  display: flex;
  position: relative;
  width: 100%;

  transition: opacity 0.1s ease-in-out;
}


.justify-content-center {
  display: flex;
  justify-content: center;
  position: relative;
}

.nowrap {
  white-space: nowrap;
}

.max-width-container {
  width: 1387px;
  padding-left: 32px;
  padding-right: 32px;
  position: relative;
}

.description-about {
  padding-right: 18px;
  margin-left: 18px;
  font-family: 'IBM Plex Sans';
  font-weight: 500;
  font-size: 16px;
  color: #FFFFFF;
  letter-spacing: 0.23px;
  line-height: 22px;
  position: relative;
  .light-text {
    color: #FFFFFF;
  }
  .dark-text {
    color: rgb(36, 50, 53);
  }
  h2 {
    line-height: 26px;
    margin: 10px 0px 30px 00px;
    
  }
}

.zone-1-inner-about {
  padding-bottom: 44px;
  padding-top: 24px;
  margin-left: auto;
  margin-right: auto;
  max-width: 1387px;
  padding-left:18px;
  padding-right:18px;
  color: white;
  h1 {
    font-size: 40px;
    line-height: 46px;
    font-family: 'IBM Plex Sans';
    font-weight: 500;
    letter-spacing: -1.0px;
    color: #E4E4E4;
    margin-top: 0;
    margin-bottom: 0;
    &:first-letter {
      margin-left: -0.08em;
    }
  }
  h2 {
    font-family: 'IBM Plex Sans';
    font-weight: 500;
    font-size: 26px;
    color: #E4E4E4;
    letter-spacing: -0.15px;
    line-height: 36px;
  }
  p {
    font-family: 'IBM Plex Sans';
    font-weight: 500;
    font-size: 16px;
    color: #E4E4E4;
    letter-spacing: 0.3px;
    line-height: 22px;
  }
}
.look-down {
  display: none;
}

@media only screen and (max-width: 768px) {
  .max-width-container {
    padding-left: 18px;
    padding-right: 18px;
  }
}

@media only screen and (min-width: 768px) {
  .firm-card-image{
    max-height: 420px !important;
  }
  .pcw-max-width {
    margin-right: 20px !important;
  }
  .look-down {
    margin-left: auto;
    margin-right: auto;
    display: flex;
   justify-content: center;
   position: relative;
   transform: scale(0.8);
   margin-top: -8px;
   margin-bottom: 24px;
  }
  .zone-1-inner-about {
    padding-left:32px;
    padding-right:32px;
    padding-bottom: 84px;
    h1 {
      font-size: 64px;
      line-height: 72px;
      letter-spacing: -1.2px;
    }
  }
  .description-about {
    padding-right: 24px;
    margin-left: 32px;
  }
}
.description-about-inner {
  max-width: 737px;
  h1 {
    font-family: 'IBM Plex Sans';
    font-weight: 500;
    font-size: 36px;
    letter-spacing: -0.6px;
    line-height: 44px;
    margin-top: 18px;
    margin-bottom: 18px;
  }

}
.portfolio-zone-1{
  display: flex;
  flex-direction: column;
  position: relative;
}

.featured-panel-approach {
  margin-left: 18px;
  margin-right: 18px;
  z-index: 0;
  color: #FFFFFF;
  margin-bottom: 64px;
  p {
    font-family: 'IBM Plex Sans';
    font-weight: 500;
    font-size: 18px;
    letter-spacing: 0.0px;
    line-height: 24px;
    margin-top: 0px;
    margin-bottom: 18px;
  }
  h1 {
    // font-family: 'IBM Plex Sans';
    // font-weight: 500;
    // font-size: 44px;

    // letter-spacing: -0.6px;
    // line-height: 48px;
    margin-top: 26px;
    // margin-bottom: 32px;
  }
  h2 {
    font-family: 'IBM Plex Sans';
    font-weight: 600;
    font-size: 36px;

    letter-spacing: -0.6px;
    line-height: 44px;
    margin-top: 18px;
    margin-bottom: 18px;
  }
}

.approach-bg-img {
  position: absolute;
  top: 0px;
  height: 100%;
  width: 100%;
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; 
  text-shadow: 2px 2px 22px rgb(17, 66, 131);
}
.approach-description{
  font-family: 'IBM Plex Sans';
  font-weight: 600;
}
.approach-header {
  padding-top: 28px;
  position: relative;
  text-shadow: none;
}
.leadership-header {
  padding-top: 28px;
}



.leadership-bg {
  background-color: #efefef;

}

@media only screen and (min-width: 768px) {
  .featured-panel-approach {
    margin-left: 32px;
    margin-right: 64px;
  }
  .firm-card-image{
    height: 320px !important;

  }
}
@media only screen and (min-width: 996px) {
  .portfolio-zone-1{
    position: relative;
    flex-direction: row;
    padding-bottom: 40px;
  }
  .banner {  
    height: 140px;  
    overflow: hidden;  

  }

  .featured-panel-approach {
    // background-color: var(--blue-1);
    // padding: 6px;
    margin-bottom: 20px;
    width: 80vw;
    width: fill-available;
    margin-right: 32px;
    margin-top: 94px;
    margin-left: 64px;
  }
  
  .header-and-desc {
    position: sticky;
    position: -webkit-sticky;
    top:var(--header-height);

    align-self: flex-start;
  }

  .description-about-inner{
    max-width: 480px;
    min-width: 390px;
    width: 420px;

    max-width: 450px;
    min-width: 330px;
    width: 320px;
  }

  .description-about {
    position: relative;
    padding-left:calc((100vw - 1392px)/2);
    margin-left: 32px;
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 1200px) {
  .banner {  
    height: 180px;    
  }
  .grid-image-above {
    margin-left:32px;
    height: 600px;
  }
  .firm-card-image{
    max-height: 800px;
    height: 300px !important;
  }
}

@media only screen and (min-width: 1600px) {
  .sticky-profile {
    position: sticky;
    top: 124px;
  }
 
}

.leadership-panel {
  color: var(--blue-1) ;
}

.peoplemap {
  margin-bottom: 32px;
}


.pcw-max-width {
  max-width: 400px !important;
  //padding-right:20px;
}

.firm-cards {
  color: rgb(222, 235, 249) !important;

  background-color: #03031b;
  padding: 0px 2px 8px 8px;
  min-height: 68px;
}

