.contact-page-back {
  background-color: var(--blue-1);
  min-height: 594px;
}
.description-contact-inner {
  max-width: 500px;
  min-width: 200px;
  display: flex;
  flex-direction: column;
}
.contact-zone{
  position: relative;
}
.contact-block {
  margin-right: 24px;
  h1 {
    font-family: 'IBM Plex Sans';
    font-weight: 600;
    font-size: 36px;
    color: #FFFFFF;
    letter-spacing: -0.6px;
    line-height: 44px;
    margin-top: 18px;
    margin-bottom: 18px;
  }
  h2 {
    font-family: 'IBM Plex Sans';
    font-weight: 600;
    font-size: 26px;
    color: rgb(255, 255, 255);
    letter-spacing: -0.15px;
    line-height: 32px;
    margin-bottom: 0;
  }
  flex-grow: 1;
}
.featured-panel-contact {
  display: block;
  margin-bottom: 36px;
  width: auto;
  margin-right: 32px;
  margin-left: 12px;
  margin-top: 32px;
}

.featured-panel-gform {
  display: block;
  //margin-bottom: 36px;
  width: 400px;
  margin-right: 0px;
  //margin-left: 0px;
  margin-left: 16px;
  //margin-top: 68px;
  margin-top:0px;
  padding-top: 6px;
  border-radius: 12px;
  background-color: #f3f3f3;

  height:480px;
  overflow: hidden;
}

.constant-contact-block {
  margin-top: 22px;
  margin-right: 12px;
  margin-left: 12px;
}

@media only screen and (min-width: 768px) {
  .description-contact-inner {
    flex-direction: row;
    align-items: center;
    align-items: baseline;
  }
  .contact-block {
    min-width: 320px;
  }
  .constant-contact-block {
    margin-top: 20px;
    min-width: 300px;
    max-width: 650px;
    margin-left: 32px;
  }

  .featured-panel-gform {
    margin-left: 32px;
  }

  
  
}

@media only screen and (min-width: 996px) {

  .constant-contact-block {
    margin-top: 80px;
    min-width: 300px;
    max-width: 450px;
  }
  .featured-panel-gform {
    margin-bottom: 36px;
    margin-left: 0px;
    margin-top: 80px;
  }

  .contact-block {
    min-width: 260px;
    margin-right: 12px;
  }
  .featured-panel-contact {
    display: none;
  }
  .description-contact-inner {
    flex-direction: column;
max-width: 350px;
  }

  .smaller-column{
    max-width: 270px;
  }
}

@media only screen and (min-width: 1200px) {
  .constant-contact-block {
    margin-top: 80px;
    min-width: 380px;
    max-width: 450px;
  }
  .featured-panel-contact {
    display: block;
    margin-bottom: 20px;
    margin-right: 32px;
    margin-top: 80px;
  }
 
}
  