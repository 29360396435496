.testimonial-page-back {
  background-color: var(--blue-2);
  min-height: 720px;
  position: relative;
}




.portfolio-section {
  position: relative;
}


.main-image {
  object-fit: cover;
  width: 100%;
  height: 230px;
  &:hover{
    img {
      outline:1px solid white;
    }
  }


}
.project-card-wrap{
  display: flex;
  flex-direction: column;
}
.card-img {
  display: flex;
  position: relative;
  width: 100%;

  transition: opacity 0.1s ease-in-out;
}


.justify-content-center {
  display: flex;
  justify-content: center;
  position: relative;
}

.max-width-container {
  width: 1387px;
  padding-left: 32px;
  padding-right: 32px;
  position: relative;
}

.description-testimonials, .description-portfolio {
  padding-right: 18px;
  margin-left: 18px;
  font-family: 'IBM Plex Sans';
  font-weight: 500;
  font-size: 16px;
  color: white;
  letter-spacing: 0.23px;
  line-height: 22px;
  position: relative;
  h2 {
    line-height: 26px;
    margin: 10px 0px 8px 00px;
    
  }
}



@media only screen and (max-width: 768px) {
  .max-width-container {
    padding-left: 18px;
    padding-right: 18px;
  }
  // // does this fix the testimonials hash bug?
  // .featured-projects {
  //   min-height: 2000px;
  //   background-color: fuchsia;
  // }
  // .min-height-for-hash {
  //   min-height: 640px;
  //   background-color: fuchsia;
  // }
}

@media only screen and (min-width: 768px) {
  .description-portfolio, .description-testimonials {
    padding-right: 24px;
    margin-left: 32px;
  }
  .featured-panel {
    margin-right: 32px;
    margin-left: 32px;
  }
}

.description-portfolio-inner, .description-testimonials-inner {
  max-width: 737px;
}
.portfolio-zone-1{
  display: flex;
  flex-direction: column;
  position: relative;
}

// // does this fix the testimonials hash bug?
// .featured-projects {
//   min-height: 1600px;
//   background-color: red;
// }
// .min-height-for-hash {
//   min-height: 640px;
//   background-color: red;
// }

@media only screen and (min-width: 996px) {



  // .featured-projects {
  //   min-height: 1000px;
  //   background-color: rgb(255, 122, 45);
  // }
  // .min-height-for-hash {
  //   min-height: 640px;
  //   background-color: rgb(255, 122, 45);
  // }


  .portfolio-zone-1{
    position: relative;
    flex-direction: row;
    padding-bottom: 40px;
  }

  .featured-panel {
    // background-color: var(--blue-1);
    // padding: 6px;
    margin-bottom: 20px;
    width: 80vw;
    width: fill-available;
    // margin-right: 32px;
    // margin-top: 94px;


    margin-right: 20px;
    margin-top: 42px;
    margin-left: 26px;
  }

  
  .header-and-desc {
    position: sticky;
    position: -webkit-sticky;
    top:var(--header-height);
    align-self: flex-start;
    
  }

  .description-portfolio-inner, .description-testimonials-inner {
    max-width: 480px;
    min-width: 390px;
    width: 420px;
  }

  .description-portfolio,   .description-testimonials  {
    position: relative;
    padding-left:calc((100vw - 1392px)/2);
    margin-left: 32px;
    margin-bottom: 20px;
  }

}

