// .logo {
//   display: flex;
//   margin-left: 36px;
//   margin-right: 36px;
//   margin-top: auto;
//   margin-bottom: auto;
//   img {
//     max-width: auto;
//     max-height: 36px;    
    
//   }
// }

.header-a {
  border-top: 4px solid #B4CD7E;
  height: 86px;
  width: auto;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
}

.navigation {
  padding-right: 36px;
  color: #80A0B6;
  font-weight: 500;
  display: flex;
  flex-direction: row;
}

.nav-link {
  display: flex;
  flex-direction: row;
  padding-top: auto;
  padding-bottom: auto;
  height: 100%;
  transition: 0.1s;
  border-radius: 0 0 0.8em .8em;
  span {
    padding-left: 36px;
    padding-right: 36px;
    margin-top: auto;
    margin-bottom: auto;
  }
  & :hover{
    text-decoration: none;
    background-color: #fafafa;
  }
}

