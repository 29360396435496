
$people-text-color: #3e3e3e;
$people-text-color-medium: #727272;
$light-box-color: #f0f0f0;
$people-background-color: #e4e4e4;

.people-text-color {
  color: $people-text-color
}

.grid-texture {
  background-image:       linear-gradient(0deg, transparent 24%, rgba(0, 0, 0, .05) 25%, rgba(0, 0, 0, .05) 26%, transparent 27%, transparent 74%, rgba(0, 0, 0, .05) 75%, rgba(0, 0, 0, .05) 76%, transparent 77%, transparent), linear-gradient(90deg, transparent 24%, rgba(0, 0, 0, .05) 25%, rgba(0, 0, 0, .05) 26%, transparent 27%, transparent 74%, rgba(0, 0, 0, .05) 75%, rgba(0, 0, 0, .05) 76%, transparent 77%, transparent);
  height:100%;
  background-size:34px 34px;
  background-position: left 13px bottom 13px;
}

.person-page-back {
  background-color: #ffffff;
 
}

.profile-wrap-0 {

  margin: 0;
}

.profile-wrap-A {
  background-color: $people-background-color;
  padding: 0px;
  margin-top: -12px;
}

.profile-wrap-B {
  background-color: $light-box-color;
  padding: 56px;
  padding-top: 29px;
  margin-bottom:50px;
  padding-bottom: 30px;

  box-shadow:
  0px 10px 20px hsla(0, 0%, 0%, 0.02),
  0px 10px 20px hsla(0, 0%, 0%, 0.02),
  0px 10px 30px hsla(0, 0%, 0%, 0.02),
  0px 30px 40px hsla(0, 0%, 0%, 0.02),
  0px 40px 80px hsla(0, 0%, 0%, 0.002);
}


.profile-wrap-Col {
  max-width: 1400px;
}



.profile-box-1{
  background-color: #cad321;
  min-height: 300px;
}
.profile-box-2 {
  //background-color: #a55dd3;
  min-height: 300px;
  max-width: 510px;
}

.profile-box-image {
  //background-color: #413846;  
  margin-bottom: 12px;
  img {
    max-width: 100%;
    padding-right: none;
  }
}

.people-name {
  font-family: "IBM Plex Sans";
    font-weight: 500;
    font-size: 40px;
    color: $people-text-color;
    letter-spacing: -0.24px;
    line-height: 40px;
    margin: 0;
    margin-left: -3px;
}

.people-position{
  font-family: "IBM Plex Sans";
  font-weight: 500;
  font-size: 19px;
  color: $people-text-color;
  letter-spacing: 0;
  margin-top: -10px;
  margin-bottom: 16px;
}

.people-accreditation{
  font-family: "IBM Plex Sans";
  font-weight: 600;
  font-size: 22px;
  color: $people-text-color;
  letter-spacing: 0;
  margin-top: 8px;
  margin-bottom: 16px;
  min-height: 1px;
}

.people-description{
  p{
    font-family: "IBM Plex Sans";
    //font-weight: 400 !important;
    font-size: 17px !important;
    line-height: 1.31;
    color: $people-text-color;
    letter-spacing: 0.015em;

  }
}

.people-description-overlay {
  background: linear-gradient(0deg, $people-background-color 0%, rgba(2, 33, 62, 0) 9%), linear-gradient(180deg, $light-box-color 0.5%, rgba(2, 33, 62, 0) 5%);
}

.divider {
  border-top: 2px solid rgb(182, 182, 182);
  margin-top: 18px;
}




.featured-projects-wrap-fix {
  display: flex;
  flex-wrap: wrap;
}



.people-project-image-wrap{
  width: 500px;
  height: 320px;
  overflow: hidden; 
}

.people-project-image {
  width: 100%;
  height: auto;
  object-fit: cover; 
}

.people-project-card-text {
  color: $people-text-color-medium;
}

.featured-projects-header {
  font-size: 28px;
  font-weight: 400;
  color: $people-text-color;
}


@media only screen and (min-width: 768px) {
  .people-nub {
   width:24px;
  }
  .profile-wrap-A {
    padding: 30px;
    padding-top: 45px;
  }
  .people-description {
    max-width: 538px;
    border-top: none !important;
  }
}

@media only screen and (min-width: 992px) {
  .profile-box-image {
    padding-right:75px;
    img {
      padding-right: 30px;
      padding-top: 5px;
    }
  }
  .profile-box-2 {
    max-width: none;
  }
  // .flex-odd:nth-child(odd){
  //   padding-right: 18px;
  // }
}



