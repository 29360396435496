.project-page-back {
  background-color: var(--blue-0);
}

.nub {
  height: 6px;
  width: 24px;
  background-color: var(--blue-2);
  margin-top: 5px;
  margin-bottom: -14px;
  margin-left: -40px;
  left: 0;
}

.single-project-header {
  margin-left: 32px;
  margin-right: 24px;
  .title {
    font-family: 'IBM Plex Sans';
    font-weight: 500;
    font-size: 40px;
    color: #FFFFFF;
    letter-spacing: -0.24px;
    line-height: 40px;
    margin: 0;
  }
  .location {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 500;
    font-size: 16px;
    color: #FFFFFF;
    letter-spacing: 0;
    margin-top: 16px;
    margin-bottom: 16px;
  }
  .description {
    font-family: 'IBM Plex Sans';
    font-weight: 500;
    font-size: 16px;
    color: #FFFFFF;
    letter-spacing: 0.23px;
    line-height: 22px;
  }

}

.read-more-link {
  font-family: 'IBM Plex Sans';
  font-weight: 600;
  font-size: 14px;
  color: #FFFFFF;
  letter-spacing: 1.22px;
  height: 40px;
  display: inline-flex;
  width: 100%;
  align-items: center;
  //justify-content: center;    
  text-decoration: none;
  cursor: pointer;
}


.description{
  blockquote{
    font-family: 'IBM Plex Sans';
    font-weight: 500;
    font-style: italic;
    background-color: #EFEFEF;
    padding: 30px;
    margin: 0px;
    // margin-right: -32px;
    color: var(--blue-0);
  }
  strong {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 600;
  }
}

.single-project-description-continued {
  padding-bottom: 32px;
}

.single-project-banner {
  background-color: rgb(205, 219, 230);
  margin-bottom: 12px;
}
.single-project-main-image {
  margin-bottom: -4px;
  object-fit: cover;
  width: 100%;
  height: 80px;
  opacity: 0.9;
  mix-blend-mode: luminosity;
}

.single-project-article {
  height: 100%;
  min-height: 700px;
  background-color: var(--blue-0);
  margin-top: -12px;
}

.gallery-inner {

  padding-left: 12px;
  padding-right: 12px;
  padding-top: 12px;
  // margin-right: 12px;
  // margin-left: 12px;


  // background-color: #c7c7c7;
  // background-color: #011323;
  background-color: #ffffff;
}

.gallery {

  width: 100%;
  padding-top: 24px;
  height: fit-content;
  img {position: relative;}
}

.project-page-image-gallery-img {
  display: flex;
  justify-content: center;
  img {
    // object-fit: cover;
    object-fit: contain;
    width: 100%;
    height: 100%;
    padding-bottom: 12px;
  }
}

.breadcrumbs {
  text-transform: uppercase;
  font-family: 'IBM Plex Sans';
  color: #efefef;
  letter-spacing: 0.12em;
  font-weight: 700;
  font-size: 0.88em;
  .breadcrumb {
    display: inline-flex;
    flex-direction: column;
    height: 40px;
    justify-content: center;
  }
  a {
    color:#efefef;
  }
}
.outlink-bottom {
  text-transform: capitalize;
  font-family: 'IBM Plex Sans';
  font-weight: 500;
  font-size: 18px;
  color: #FFFFFF;
  letter-spacing: 0.26px;
  height: 24px;
  border-bottom: 1px solid #979797;
  max-width: 1330px;
  flex: 1 1;
  margin-left: 32px;
  margin-right: 32px;
  margin-top: 12px;
  margin-bottom: 36px;
}
.outlink-bottom-wrap {
  display: flex;
  justify-content: center;
}


.MainGalleryImage {
  display: none;
}

.grid-zone-project {
  margin-left: 9px;
  // width: 60px;
  margin-bottom: 20px;
  font-family: 'IBM Plex Mono';
  font-size: 24px;
  color: #BECCDA;
  letter-spacing: -2px;
  text-align: left;
  line-height: 22px;
  overflow: hidden;
  height: 800px;
  pointer-events: none;
  z-index: -1;
  position: absolute;
  margin-top: 7px;
  opacity: 0.1;
}

.MainGalleryImage-SingleException {
  display: flex;
  padding-bottom: 12px;
  max-height: 594px;
  overflow: hidden;
  // width: 900px;
  img {
    // max-height: 550px;
    // max-width: 900px;
    // max-height: 550px;
    width: 100%;
    object-fit: contain;
  }
}

.disappear-below-1200 {
  display: none;
}


///////////
@media only screen and (min-width: 768px) {
  .nub {

    width: 32px;

  }
  .single-project-main-image{
    height: 130px;
  }
  .description {
    // max-width: 737px;
    max-width: 505px;
  }
  .grid-zone-project {
    width: auto;
    height: 860px;
  }
  .gallery-inner {
    margin-right: 12px;
    margin-left: 12px;
    border-bottom: 4px solid #03213e;
  }
}
@media only screen and (min-width: 996px) {

}

@media only screen and (min-width: 1200px) {
  .nub {
    width: 24px;
    margin-left: -38px;
  }
  .grid-zone-project {
    height: 780px;
    position: fixed;
  }
  .project-page-image-gallery-img {
    max-height: 740px;
  }
  .single-project-header{
    position: sticky;
    position: -webkit-sticky;
  }
  .gallery {
    padding-top: 22px;
  }
  
  .gallery-inner {
    position: sticky;
    position: -webkit-sticky;
    top: var(--header-height);
    width: auto;
    min-width: 700px;
    // max-width: 892px;
    max-width: 990px;
    padding-top: 12px;
    margin-left: 0px;
    margin-right: 18px;
  }
  .thumbs{
    display: flex;
    max-height: 100px;
    justify-content: center;
    * {
      padding-right: 10px;
      cursor: pointer;
    }
    :last-child {
      padding-right: 0px;
    }
  }
  .project-page-image-gallery-img {
    max-width: 180px;
    display: flex;
    justify-content: center;
    flex: 1 1;
    img {
      object-fit: cover;
      padding-bottom: 12px;
      transition: opacity 0.3s;
      &:hover {
        opacity: 0.5;
      }
    }
  }

  .MainGalleryImage {
    display: flex;
    padding-bottom: 12px;
    min-width: 718px;
    // min-height: 594px;
    min-height: 594px;
    max-height: 594px;

    overflow: hidden;
    justify-content: flex-start;
    // width: 900px;
      img {
        // max-height: 550px;
        // max-width: 900px;
        // max-height: 550px;
        width: 100%;
        object-fit: contain;
        // object-fit: cover;
      }
    }

  .single-project-main-image {
    display: none;
  }
  .single-project-header {
    min-width: 360px;
    max-width: 420px;
    position: sticky;
    position: -webkit-sticky;
    top: var(--header-height);
    // height: 89vh;
    // overflow-y: scroll;
    // overflow-x: hidden;
    padding-bottom: 32px;
    padding-right: 20px;
  }


  .header-and-gallery {
    max-width: 1560px;
    // padding-left: 84px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    padding-bottom: 48px;
    justify-content: space-between;
  }
  .description-wrap {
    position: relative;
  
    .description{

      .description-inner {

        position: sticky;
        position: -webkit-sticky;
        top: var(--header-height);

        // height: 55vh;
        height: auto;

        overflow-y: scroll;
        overflow-x: hidden;
        padding-bottom: 40px;
        padding-right: 20px;
        blockquote{
          margin-right: -12px;
        }

 
      }
      .read-more-height {
        height: 50vh;
      }
      border-top: 1px solid var(--blue-3);
    }
  }

   //Chrome, Safari, Opera
   body ::-webkit-scrollbar { width: 0 !important }  

   //Firefox
   body { overflow: -moz-scrollbars-none; }

   //Internet Explorer, Edge
   body { -ms-overflow-style: none; }
  
  .description-overlay {
    border-bottom: 1px solid var(--blue-3);
    border-top: 1px solid var(--blue-3);
    position: absolute;
    top: 0px;
    bottom   : 0px;

    width: 100%;
    z-index: 1;
    pointer-events   : none;
    

    padding-bottom: 32px;
    padding-right: 20px;
    
    
    background: linear-gradient(0deg, rgba(2,33,62,1) 0%,  rgba(2,33,62,0) 9%), linear-gradient(180deg, rgba(2,33,62,1) 0.5%,  rgba(2,33,62,0) 5%);
    // opacity: 0.3;
    // background-color: yellowgreen;
    // background: linear-gradient(0deg, rgba(2,33,62,1) 0%,  rgba(2,33,62,0) 5%);
  }
  .disappear-below-1200 {
    display: block;
  }
  .disappear {
    display: none;
  }
  .read-more-box{
    margin-top: 10px;
  }
  .single-project-top-clipper {
    height: 70px;
    width: 100%;
    background-color: var(--blue-0);
    position: fixed;
    top:0px;

    z-index: 1;
  }
}

