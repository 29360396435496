.google-map-code {
  width: fit-content;
  height:480px;
}

.small-map {
  display: block;
  margin-right: auto;
  margin-left: auto;
  height:480px;
}
.large-map {
  display: none;
  height:480px;
}
.medium-map {
  display: none;
  height:480px;
}
.extralarge-map {
  display:  none;
  height:480px;
}
@media only screen and (min-width: 550px) {
  .small-map {
    display: none;
  }
  .medium-map {
    display: block;
  }
  .google-map-code {
    margin-right: auto;
    margin-left: auto;
  }
 
}
@media only screen and (min-width: 768px) {
  .medium-map {
    display: none;
  }
  .large-map {
    display: block;
  }
  .google-map-code {
    margin-left: 20px;
  }
}
@media only screen and (min-width: 1200px) {
  .large-map {
    display: none;
  }
  .extralarge-map {
    display: block;
  }
  .google-map-code {
    margin-left: 20px;
  }
}