.portfolio-page-back {
  background-color: var(--blue-2);
  min-height: 720px;
  position: relative;
}

.featured-panel {
  // background-color: var(--blue-1);
  // padding: 6px;
  margin-bottom: 20px;
  width: auto;

  margin-top: 32px;

  margin-right: 18px;
  margin-left: 18px;
  padding: 1px;
}

.portfolio-section {
  position: relative;
}


.main-image-portfolio {
  object-fit: cover;
  width: 100%;
  height: 380px;
  transition: transform 0.3s ease-in-out;
  
  &:hover{
    transform: scale(1.1);
   img{
    outline:1px solid white; 
   }   
  }


}
.project-card-wrap-wrap {
  margin-bottom: 18px;
}
.project-card-wrap{
  display: flex;
  flex-direction: column;

  &:hover {
    .main-image-portfolio{
      outline: 1px solid white;
    }
  }
}
.card-img {
  display: flex;
  position: relative;
  width: 100%;
  background-color: rgb(76, 115, 156);
  transition: opacity 0.1s ease-in-out;

}
.card-description {
  font-family: 'IBM Plex Sans';
  font-weight: 600;
  font-size: 24px;
  letter-spacing: -0.24px;
  line-height: 28px;
  color: #efefef;
  h4 {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0px;
    margin-top: 6px;
    margin-left: 1px;

    text-transform: uppercase;
    letter-spacing: 0.1em;
    font-weight: 700;

  }

  display: flex;
  flex-direction: column;
  bottom:0;
  left: 0;

  width: 100%;
  margin-bottom: 18px;
  * {
    width: inherit;
    margin: 0px;
    margin-top: 10px;
  }
}

.justify-content-center {
  display: flex;
  justify-content: center;
  position: relative;
}

.max-width-container {
  width: 1387px;
  padding-left: 32px;
  padding-right: 32px;
  position: relative;
}

.description-testimonials, .description-portfolio {
  padding-right: 18px;
  margin-left: 18px;
  font-family: 'IBM Plex Sans';
  font-weight: 500;
  font-size: 16px;
  color: white;
  letter-spacing: 0.23px;
  line-height: 22px;
  position: relative;
  h2 {
    line-height: 26px;
    margin: 10px 0px 8px 00px;
    
  }
}



@media only screen and (max-width: 768px) {
  .max-width-container {
    padding-left: 18px;
    padding-right: 18px;
  }
}
@media only screen and (min-width: 768px) {
  .featured-panel {
    margin-right: 24px;
    margin-left: 24px;
  }
  .main-image-portfolio {
    height: 480px;
  }
  .description-portfolio, .description-testimonials {
    padding-right: 24px;
    margin-left: 32px;
  }
}

.description-portfolio-inner, .description-testimonials-inner {
  max-width: 737px;
  h2 {
    margin-top: 8px;
    margin-bottom: 8px;
  }
}
.portfolio-zone-1{
  display: flex;
  flex-direction: column;
  position: relative;
}

// // does this fix the testimonials hash bug?
// .featured-projects {
//   min-height: 1600px;
//   background-color: red;
// }
// .min-height-for-hash {
//   min-height: 640px;
//   background-color: red;
// }

@media only screen and (min-width: 996px) {
  .main-image-portfolio{
    height: 340px;
  }

  // .featured-projects {
  //   min-height: 1000px;
  //   background-color: rgb(255, 122, 45);
  // }
  // .min-height-for-hash {
  //   min-height: 640px;
  //   background-color: rgb(255, 122, 45);
  // }


  .portfolio-zone-1{
    position: relative;
    flex-direction: row;
    padding-bottom: 40px;
  }

  .featured-panel {
    // background-color: var(--blue-1);
    // padding: 6px;
    margin-bottom: 20px;
    width: 80vw;
    width: fill-available;
    // margin-right: 32px;
    // margin-top: 94px;

    margin-right: 24px;
    margin-top: 42px;
    margin-left: 26px;

  }

  
  .header-and-desc {
    position: sticky;
    position: -webkit-sticky;
    top:var(--header-height);
    align-self: flex-start;
    
  }

  .description-portfolio-inner, .description-testimonials-inner {
    max-width: 480px;
    min-width: 390px;
    width: 420px;
  }

  .description-portfolio,   .description-testimonials  {
    position: relative;
    padding-left:calc((100vw - 1392px)/2);
    margin-left: 32px;
    margin-bottom: 20px;
  }

}

@media only screen and (min-width: 1200px) {
  .main-image-portfolio{
    height: 230px;
  }
  .project-card-wrap {
    margin-right: 18px;
  }
}