
.home-background {
  background-color: var(--blue-2);

  h1 {
    color:white;
    font-weight: 300;
  }

}

.look-down {
  margin-left: auto;
  margin-right: auto;
  display: flex;
 justify-content: center;
 position: relative;
 transform: scale(0.8);
 margin-top: -8px;
 margin-bottom: 24px;
}

.carousel-wrap {
  margin-left: auto;
  margin-right: auto;
  max-width: 1387px;
  padding-left:32px;
  padding-right:32px;
  margin-bottom: -44px;
  min-height: 440px;
}

.zone-1{
  width: 100%;
  background-color: var(--blue-0);
  
}
.zone-1-inner {
  padding-bottom: 84px;
  padding-top: 64px;
  margin-left: auto;
  margin-right: auto;
  max-width: 1387px;
  padding-left:18px;
  padding-right:18px;
  color: white;
  h1 {
    font-size: 40px;
    line-height: 46px;
    letter-spacing: -1.0px;
    font-family: 'IBM Plex Sans';
    font-weight: 500;
    color: #E4E4E4;
    margin-top: 0;
    margin-bottom: 0;

  }
  h2 {
    font-family: 'IBM Plex Sans';
    font-weight: 500;
    font-size: 26px;
    color: #E4E4E4;
    letter-spacing: -0.15px;
    line-height: 36px;
  }
  p {
    font-family: 'IBM Plex Sans';
    font-weight: 500;
    font-size: 16px;
    color: #E4E4E4;
    letter-spacing: 0.3px;
    line-height: 22px;
  }
}
  .outlink {
    font-family: 'IBM Plex Sans';
    font-weight: 500;
    font-size: 22px;
    color: white;
    letter-spacing: -0.15px;
    line-height: 40px;
  }
 
  .outlink-arrow {
    color: var(--green-0);
    padding-right:10px;
  }
  .outlink-back {
    background-color: var(--blue-0);
    transition: background-color 0.2s ease;
    border-radius: 4px;
    margin-top: 5px;
    margin-bottom: 5px;

    display: flex;
    justify-content: space-between;
    .outlink-text {
      padding-left: 10px;
      text-decoration: none;
      text-decoration: underline solid transparent;
      transition: 
        text-decoration 0.2s ease, 
        color 0.2s ease,
    }
    box-sizing: border-box;
    &:hover {  
      background-color: var(--blue-2);  
      border: 1px solid white;
      margin-top: 4px;
      margin-bottom: 4px;

    
    }
  }

.grid-zone {
  margin-top: 16px;
  margin-bottom: 20px;
  opacity: 0.15;
  font-family: 'IBM Plex Sans';
  font-weight: 400;
  font-size: 36px;
  color: #BECCDA;
  letter-spacing: -0.2px;
  text-align: right;
  line-height: 29.5px;
  overflow: hidden;
  height: 612px;
  pointer-events: none;
  z-index: -1;
  position: absolute;

}
.grid-zone-top {
  height: 670px;
  margin-top:-10px;
  opacity: 0.35;
  padding-left: 0.56em;
  text-align: left;
}

.grid-image {
  z-index: 1;
  position: relative;
  padding-left: 20px;
  padding-top: 46px;
}
.grid-image-img{
      img {
        object-fit: cover;
        height: 650px;
        width: 90%;
        margin-left: 5%;
      }
}
@media only screen and (max-width: 768px) {
  .carousel-wrap {
    padding-left:0px;
    padding-right:0px;
  }
  .grid-zone {
    height: 582px;
  }
  // .zone-1-inner {
  //   h1{
  //     font-size: 64px;
  //     color: #E4E4E4;
  //     letter-spacing: -1px;
  //     line-height: 72px;
  //   }
  // }
}

@media only screen and (min-width: 768px) {
  .outlink-back {
    margin-right: 18px;
  }
  .zone-1-inner {
    padding-left:32px;
    padding-right:32px;
    h1 {
      font-size: 64px;
      line-height: 72px;
      // letter-spacing: -1.2px;
    }
  }
}

@media only screen and (min-width: 1200px) {

  .grid-image-img{


      object-fit: cover;
      height: 550px;
    
  
  }

}
