
/**
 * Demo Styles
 */

 html {
  height: 100%;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  min-height: 100%;
  position: relative;
  margin: 0;
  padding-bottom: 210px;
  display: flex;
  flex-direction: column;
  padding-top: 80px;
}
@media only screen and (min-width: 768px) {
  body {
    padding-top: var(--header-height);
  }
}
/**
 * Footer Styles
 */

.content-all {
  flex: 1 0 auto;
}
.footer-app {
  position: absolute;
  left:0;
  right: 0;
  bottom: 0;
  height: 210px;
}
@media only screen and (min-width: 768px) {
  /* .the-border {
    
    width: 100%;
    height: 100%;
    position: fixed;
    pointer-events: none;
    top: 0px;
    z-index: 1000;
    border: 8px solid white;
    border-top: 8px solid var(--green-0);
    filter: blur(16px)
    opacity(0.9);
    mix-blend-mode: soft-light;
  
  
  } */
  .the-border-2 {
    
    width: 100%;
    height: 100%;
    position: fixed;
    pointer-events: none;
    top: 0px;
    z-index: 1003;

    border: 8px solid #2d4466;
    border: 8px solid #4d6b96;
    border: 8px solid #43628d;
    border: 8px solid #061a38;
    border: 8px solid #e0e0e0;
    /* border: 8px solid #dce0e6; */
    /* border: 8px solid #efefef; */
  }
 
  /* .the-border-4 {
    width: calc(100% - 16px);
    margin-left:8px;

    height: 100%;
    position: fixed;
    pointer-events: none;
    top: 8px;
    z-index: 1002;

    border-top: 8px solid var(--green-0);
  } */
  .the-border-5 {
    width: calc(100% - 16px);
    height: calc(100% - 16px);
    margin-left:8px;
    /* border-radius: 6px 6px 6px 6px; */
    position: fixed;
    pointer-events: none;
    top: 8px;
    
    z-index: 1004;
border: 1px solid rgb(255, 255, 255)5);
    box-shadow: 0px 2px 8px 2px rgba(8, 26, 54, 0.34);
    box-shadow: 0px 2px 6px 2px rgba(7, 26, 61, 0.16);
  }
}
.the-things {
  /* margin-left: 18px;
  margin-right: 18px; */

}
