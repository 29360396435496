
.award-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform .3s;
  // border-radius: 0px 8px 8px 0px;
}

.award-image-box {
  display: flex;
  width: 100%;
  height: 220px;
  background-color: var(--blue-0);
  overflow: hidden;
  align-items: flex-end;
  
  
}

.award-image-hover {
  &:hover{
    opacity: 0.9;
    transform: scale(1.1);
    transition: transform .3s;
  }

}

.see-project {
  pointer-events: none;
  font-size: 20px;
  font-family: 'IBM Plex Mono';
  text-align: left;
  letter-spacing: 0.03em;
  position: absolute;
  color: white;
  text-shadow: 0px 4px 14px var(--blue-0);
  width: 100%;
  z-index: 2;
  padding-left: 20px;
  padding-bottom: 6px;
  padding-top: 24px;
  opacity: 1;
  transition: opacity .2s;

  background: linear-gradient(0deg, rgba(0,0,0,0.25) 0%, rgba(0,0,0,0) 100%);


  font-family: 'IBM Plex Sans';
  text-transform: uppercase;
  letter-spacing: 0.12em;
  font-weight: 700;
  font-size: 0.88em;
}

.award-image-box-hover {
  &:hover{
    .see-project {
      opacity: 1;
    }
  }
  
}

.award-bounds {
  background: #FFFFFF;
  box-shadow: 0 2px 14px 0 rgba(0,0,0,0.15);
  // border-radius: 8px;
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
}
.award-header {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  
}
.award-main-text {
  display: flex;
  flex-direction: column;
  padding: 20px;
  flex: 1;
}

.award-headline{
  font-family: 'IBM Plex Sans';
  font-weight: 600;
  font-size: 24px;
  color: #084B73;
  letter-spacing: -0.3px;
  line-height: 28px;

  margin-bottom: 8px;
}
.award-org {
  font-family: 'IBM Plex Sans';
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  color: #486F87;
  letter-spacing: -0.22px;

  margin-bottom: 6px;

}
.award-date{
  font-family: 'IBM Plex Sans';
  font-weight: 500;
  font-size: 18px;
  color: #93B1C2;
  letter-spacing: -0.22px;
}



.award-desc {
  font-family: 'IBM Plex Sans';
  font-weight: 400;
  font-size: 18px;
  color: #056198;
  letter-spacing: -0.22px;
  padding: 20px;
  padding-top: 0px;
  p {
    margin-top: 0px;
  }
}

.testimonial {
background-color: pink;
color: white;
display: flex;
justify-content: center;
margin-bottom: 10px;
margin-top: 2px;
overflow: hidden;


}

.testimonial-inner {
  padding-top:30px;
  padding-bottom:18px;
  max-width: 620px;
  width: 100%;
  padding-left: 32px;
  padding-right: 28px;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 0.01em;
  font-weight: 400;
  strong {
    font-weight: 700;
    letter-spacing: 0.02em;
  }
}

.tall-box{
  height: 360px;
}

@media only screen and (min-width: 529px) {
  .tall-box{
    height: 540px;
  }
}
@media only screen and (min-width: 768px) {
.testimonial-inner {
  font-size: 21px;
  line-height: 28px;

}
}

@media only screen and (min-width: 768px) { 
  .award-image-box {
    height: 280px;
  }
  .tall-box{
    height: 560px;
  }
}

@media only screen and (min-width: 996px) { 
  .award-image-box {
    height: 220px;
  }
  .tall-box{
    height: 340px;
  }
}

@media only screen and (min-width: 1100px) { 
  .award-image-box {
    height: 340px;
  }
  .tall-box{
    height: 440px;
  }
}

@media only screen and (min-width: 1200px) { 
  .tall-box{
    height: 540px;
  }
}
@media only screen and (min-width: 1400px) { 
  .tall-box{
    height: 640px;
  }
}

.fourths {
  background-color: rgb(49, 80, 121);
  overflow: hidden;
}
.thirds {
  background-color: var(--blue-1);
  overflow: hidden;
}
.seconds {
  background-color: rgb(95, 112, 92);
  overflow: hidden;
}
.ones {
  background-color: #573d4f;
  overflow: hidden;
}


