.gridbox-a {
  background-color: aquamarine;
  height: 30px;
}
.gridbox-b {
  background-color: rgb(44, 153, 117);
  height: 30px;
}
.sanity-image {
  width: 50vw;
  height: 150px;
  object-fit: cover;
}