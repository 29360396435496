
.home-background {
  background-color: var(--blue-2);

  h1 {
    color:white;
    font-weight: 300;
  }

}

.look-down {
  margin-left: auto;
  margin-right: auto;
  display: flex;
 justify-content: center;
 position: relative;
 transform: scale(0.8);
 margin-top: -8px;
 margin-bottom: 24px;
}


.zone-1{
  width: 100%;
  background-color: var(--blue-0);
  
}

  .outlink {
    font-family: 'IBM Plex Sans';
    font-weight: 500;
    font-size: 22px;
    color: white;
    letter-spacing: -0.15px;
    line-height: 40px;
  }
 
  .outlink-arrow {
    color: var(--green-0);
    padding-right:10px;
  }
  .outlink-back {
    background-color: var(--blue-0);
    transition: background-color 0.2s ease;
    border-radius: 4px;
    margin-top: 5px;
    margin-bottom: 5px;

    display: flex;
    justify-content: space-between;
    .outlink-text {
      padding-left: 10px;
      text-decoration: none;
      text-decoration: underline solid transparent;
      transition: 
        text-decoration 0.2s ease, 
        color 0.2s ease,
    }
    box-sizing: border-box;
    &:hover {  
      background-color: var(--blue-2);  
      border: 1px solid white;
      margin-top: 4px;
      margin-bottom: 4px;

    
    }
  }


.grid-zone-top {
  height: 670px;
  margin-top:-10px;
  opacity: 0.35;
  padding-left: 0.56em;
  text-align: left;
}

.grid-image {
  z-index: 1;
  position: relative;
  padding-left: 20px;
  padding-top: 46px;
  img {
    object-fit: cover;
    height: 650px;
    width: 90%;
    margin-left: 5%;
  }

}
@media only screen and (max-width: 768px) {

  .grid-zone {
    height: 582px;
  }
}

@media only screen and (min-width: 768px) {
  .outlink-back {
    margin-right: 18px;
  }
}

@media only screen and (min-width: 1200px) {

  .grid-image {

    img {
      object-fit: cover;
      height: 550px;
    }
  
  }

}
