.header-bar-class  {
  display: flex;
  flex-direction: row;
  h1{
    margin: 0;
    letter-spacing: -0.38px;
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 500;
    font-size: 50px;
    color: #FFFFFF;
    line-height: 55px;
  }
}


.header-side {
  padding-top: 10px;
  padding-bottom: 10px;
  h1 {color: rgba(1,1,1,0);}
}



.header-text {

  box-shadow: 0 4px 14px 0 rgba(0,0,0,0.25);
  background-color: var(--blue-2);
  padding-right: 24px;
  padding-top: 10px;
  padding-bottom: 10px;
  width: fit-content;
  h1{
    margin: 0;
    letter-spacing: -0.38px;
    font-family: 'IBM Plex Sans';
    font-weight: 500;

    color: #FFFFFF;

    font-size: 36px;
    line-height: 42px;
  }

  padding-left: 18px;
}

.cool{
  width: calc((100vw - 1392px)/2);
  background-color: var(--blue-2);
  height: 75px;
  margin-right: -1px;
  h1{
    margin: 0;
    letter-spacing: -0.38px;
    font-family: 'IBM Plex Sans';
    font-weight: 500;
    font-size: 50px;
    color: #FFFFFF;
    line-height: 55px;

  }
}


.black {
  background-color: #000;
}
.dark {
  background-color: var(--blue-0);
}
.medium {
  background-color: var(--blue-1);
}
.maroon {
  background-color: #9a0a0a;
}
.bluer {
  background-color: #0e5996;
}
.greener {
  background-color: #9dbd5a;
}

@media only screen and (min-width: 768px) {
  .header-text {
    h1{
      font-size: 50px;
      line-height: 55px;
    }
    padding-left: 30px;
  }
}

@media only screen and (min-width: 996px) {
  .header-text { 
    box-shadow: none;
  }
}

@media only screen and (max-width: 1392px) {
  .cool {
    display: none;
  }
}