@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@400;500;600&family=IBM+Plex+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=IBM+Plex+Serif:ital,wght@0,600;1,600&display=swap');

@import url("https://use.typekit.net/grr4hms.css");


body {
  margin: 0;
  font-family: 'IBM Plex Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-weight: 500;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: var(--blue-0);
  /* background-color: white; */


}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 300;
}
h2 {
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 400;
}
h3 {
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 500;
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

/* a:hover {
  text-decoration: underline;
} */

/* a:active {
  text-decoration: underline;
} */


/** colors **/

:root {
  --blue-0: #02213E;
  /* --blue-1: #37526C; */
  /* --blue-1: #3b5a77; */
  --blue-1: #466a8f;
  /* --blue-2: #7C97B0; */
  /* --blue-2: #6d8ba7; */
  --blue-2: #668eaf;
  --blue-3: #BCD0E2;
  --green-0: #B5D470;
  --green-0: rgb(157, 189, 90);
  --header-height: 94px;
  --footer-height: 210px;
  /* --sm: 768px;
  --md: 992px;
  --lg: 1200px;
  --xl: 1600px; */
}


/* fade in  */

.fade-in {
  opacity: 0;
  animation: fadein .31s;
  animation-delay: 0.1s;
  animation-fill-mode: forwards;
  -moz-animation: fadein .31s; /* Firefox */
  -moz-animation-delay: 0.1s; /* Firefox */
  -webkit-animation: fadein .31s; /* Safari and Chrome */
  -webkit-animation-delay: 0.1s; /* Safari and Chrome */
  -webkit-animation-fill-mode: forwards;
  -o-animation: fadein .31s; /* Opera */
  -o-animation-delay: 0.1s; /* Opera */
}
@keyframes fadein {
  from {
      opacity:0;
  }
  to {
      opacity:1;
  }
}
@-moz-keyframes fadein { /* Firefox */
  from {
      opacity:0;
  }
  to {
      opacity:1;
  }
}
@-webkit-keyframes fadein { /* Safari and Chrome */
  from {
      opacity:0;
  }
  to {
      opacity:1;
  }
}
@-o-keyframes fadein { /* Opera */
  from {
      opacity:0;
  }
  to {
      opacity: 1;
  }
}


/* fade in delay */

.fade-in-delay {
  opacity: 0;
  animation: fadein .31s;
  animation-delay: 0.8s;
  animation-fill-mode: forwards;
  -moz-animation: fadein .31s; /* Firefox */
  -moz-animation-delay: 0.8s; /* Firefox */
  -webkit-animation: fadein .31s; /* Safari and Chrome */
  -webkit-animation-delay: 0.8s; /* Safari and Chrome */
  -webkit-animation-fill-mode: forwards;
  -o-animation: fadein .31s; /* Opera */
  -o-animation-delay: 0.8s; /* Opera */
}

.fade-in-delay-more {
  position: relative;
  z-index: 2;
  opacity: 0;
  animation: fadein .9s;
  animation-delay: .6s;
  animation-fill-mode: forwards;
  -moz-animation: fadein .9s; /* Firefox */
  -moz-animation-delay: .6s; /* Firefox */
  -webkit-animation: fadein .9s; /* Safari and Chrome */
  -webkit-animation-delay: .6s; /* Safari and Chrome */
  -webkit-animation-fill-mode: forwards;
  -o-animation: fadein .9s; /* Opera */
  -o-animation-delay: .6s; /* Opera */
}


.fade-in-more {
  position: relative;
  z-index: 2;
  opacity: 0;
  animation: fadein .9s;
  animation-fill-mode: forwards;
  -moz-animation: fadein .9s; /* Firefox */
  -webkit-animation: fadein .9s; /* Safari and Chrome */
  -webkit-animation-fill-mode: forwards;
  -o-animation: fadein .9s; /* Opera */
}

@keyframes fadein {
  from {
      opacity:0;
  }
  to {
      opacity:1;
  }
}
@-moz-keyframes fadein { /* Firefox */
  from {
      opacity:0;
  }
  to {
      opacity:1;
  }
}
@-webkit-keyframes fadein { /* Safari and Chrome */
  from {
      opacity:0;
  }
  to {
      opacity:1;
  }
}
@-o-keyframes fadein { /* Opera */
  from {
      opacity:0;
  }
  to {
      opacity: 1;
  }
}
