.header {
  background-color: var(--blue-2);

  height: auto;
  display: flex;
  a {
    color: white;
    &:hover {
      text-decoration: none;
    }
  }
  // overflow: hidden;
  border-top: 8px solid var(--green-0);

  font-family: 'IBM Plex Mono';
  font-weight: 500;
  font-size: 16px;
  color: #FFFFFF;
  letter-spacing: 0.02px;
  text-align: left;
  justify-content: center;
  position: fixed;
  top: 0;
  z-index: 999;
  width: 100%;

}

.dropdown-menu {
  display: flex;
  flex-direction: column;
  position: absolute;
  top:70px;
  background-color:var(--blue-2);
  border: 1px solid #efefef;
  padding-top:6px;
}
.rolled {
  position: relative;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.7s cubic-bezier(0, 1, 0, 1);
  transition-delay: 0.3s;


  border: 0px;
  background-color: var(--blue-0);
  // left: 18px;
  // box-shadow: 0 3px 9px 0 rgba(14,21,39,0.80);
}
.unroll {
  overflow: hidden;
  max-height: 900px;
  transition: max-height 1s ease-in-out;

  position: relative;
  border: 0px;
  background-color: var(--blue-0);
  // box-shadow: 0 3px 9px 0 rgba(14,21,39,0.80);
}
.mobile-menu {
  user-select: none;
  display: flex;
  flex-direction: column;
  position: absolute;
  top:-8px;
  background-color:var(--blue-0);
  padding-top:80px;
  box-shadow: 0 3px 9px 0 rgba(14,21,39,0.80);
  width: 222px;
  height: 100vh;
  right:-231px;
  transition: right 0.6s ease-in-out;
  transition-delay: 0.4s;
  .mobile-option {
    // width: 100%;
    // padding-top: 12px;
  
    // padding-right: 20px;
    // padding-bottom: 16px;
    // margin-bottom: 0px;
    // display: flex;


    width: 100%;
    padding-top: 14px;
    padding-right: 20px;
    padding-bottom: 14px;
    margin-bottom: 0px;
    display: flex;
    font-family: 'IBM Plex Sans';
    text-transform: uppercase;
    letter-spacing: 0.12em;
    font-weight: 700;
    font-size: 0.88em;

  }
  .dropdown-item {
    padding-left: 36px;
  
  }
  


  .dropdown-item-active {
    padding-left: 32px;
  }
  
  border-left: 1px solid white;
}
.rotate-45 {
  transform: rotate(45deg);
}
.rotate-90 {
  transform: rotate(90deg);
}

.expand {
  transition: right 0.5s ease-in-out;
  right:0px;
}

.default-background-color {
  background-color: var(--blue-2);
}
.about-background-color{
  background-color: var(--blue-0);
}
.approach-background-color{
  background-color: var(--blue-2);
}
.leadership-background-color{
  background-color: var(--blue-0);
}
.project-background-color{
  background-color: var(--blue-0);
}
.portfolio-background-color{
  background-color: var(--blue-2);
}
.project-type-background-color{
  background-color: var(--blue-1);
}
.people-background-color{
  background-color: #828a9e;
}
.contact-background-color {
  background-color: var(--blue-1);
}

.header-inner {
  display: flex;
  justify-content: space-between;
  max-width: 1560px;
}

.nav-contents {
  display: flex;
  width: 100vw;
  justify-content: space-between;
  align-items: center;
}

.nav-option {
  display: inline-flex;
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 14px;
  margin-bottom: 4px;
  cursor: pointer;
  &:hover {
    // background-color: var(--blue-3);

  }
  &:focus {
    background-color: rgba(160, 201, 255, 0.281);
  }
}
.nav-option-logo {
  display: inline-flex;
  padding-top: 10px;
  padding-left: 18px;
  padding-right: 20px;
  padding-bottom: 9px;
  margin-bottom: 9px;
}
.nav-menu {
  display: none;
}
.caret {
  transition: transform 0.3s ease-in-out;
}

.caret-wrap {
pointer-events: none;
  right: 0px;
  top: 0px;
  position: absolute;
  padding-top: 12px;
  padding-left: 36px;
  padding-bottom: 12px;
  padding-right: 23px;
}

.item-and-caret {
  position: relative;
}

.nav-menu-mobile-button {
  border: 1px solid white;
  border-radius: 2px;
  display: flex;
  align-items: center;
  font-weight: 600;
  cursor: pointer;
  .plus {
    pointer-events: none;
    user-select: none;
    font-size: 42px;
    font-weight: 300;
    line-height: 40px;
    margin-bottom: 4px;
    font-family: 'IBM Plex Sans';
    transition: transform 0.5s ease-in-out;
    transform-origin: 13px 22px;
  }
  padding-top: 0px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 0px;
  margin-top: 16px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 18px;
  position: absolute;
  top: 0px;
  right: 0;
  z-index: 1;
}

.nav-active{
  // font-weight: 600;
}
.mobile-option-active {
  border-left: 4px solid white;
  padding-left: 16px;
  // background-color: rgba(160, 201, 255, 0.281);
}

.item-and-caret-and-dropdown {
  position: relative;
}

.logo {
  display: inline-flex;
  img {
    max-width: auto;
    max-height: 44px; 
  }
}

@media only screen and (min-width: 768px) {
  .header {
    border-top: 18px solid var(--green-0);
    height: var(--header-height);
  //  height: 80px;
    // padding-top: 14px;
    // ^ use without 8px site border 
    padding-top: 6px;
  }
  .item-and-caret{
    display: flex;
    // margin-right: 12px;
  }
  .item-and-caret-item {
    .mobile-option {
      padding-right: 35px;
      padding-left: 18px;
    }
  }
  .mobile-menu {
    // display: none;
    flex-direction: row;
    position: inherit;
    top: 0px;
    background: none;
    padding-top: 0px;
    box-shadow: none;
    width: auto;
    height: auto;
    border-left: none;
    margin-right: 10px;
    margin-top: -8px;
    .dropdown-item {
      padding-left: 18px;
      // border-top: 1px solid rgba(255, 255, 255, 0);
      // border-bottom: 1px solid rgba(255, 255, 255, 0);
      &:hover {
        background-color: rgba(160, 201, 255, 0.281);
        // border-top: 1px solid var(--blue-0);
        // border-bottom: 1px solid var(--blue-0);
      }
    }
    .last-one {
      &:hover {
  
        // border-top: 1px solid var(--blue-0);
        // border-bottom: 1px solid rgba(255, 255, 255, 0);
      }
    }
    .first-one {
      &:hover {
    
        // border-top: 1px solid rgba(255, 255, 255, 0);
        // border-bottom: 1px solid var(--blue-0);
      }
    }
    .nav-one {
      // border: 1px solid rgba(255, 255, 255, 0);
      &:hover {
        background-color: rgba(160, 201, 255, 0.281);
        // border: 1px solid white;
      }
    }
    .dropdown-item-active {
      padding-left: 18px;
    }
  }


  .rolled {
    position: absolute;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.7s cubic-bezier(0, 1, 0, 1);
    transition-delay: 0.3s;
  
  
    border: 0px;
    background-color: var(--blue-0);
    // left: 18px;
    box-shadow: 0 3px 9px 0 rgba(14,21,39,0.80);
  }
  .unroll {
    overflow: hidden;
    max-height: 900px;
    transition: max-height 1s ease-in-out;
  
    position: absolute;
    border: 0px;
    background-color: var(--blue-0);
    box-shadow: 0 3px 9px 0 rgba(14,21,39,0.80);
  }




  .mobile-option-active {
    border: none;
    padding-left: 20px;
  }

  .caret-wrap {
    position: absolute;
    padding-left: 8px;
    padding-bottom: 12px;
    right: 0px;
    padding-right: 12px;
    padding-top: 14px;
  }
  .logo {
    img {
      max-height: 54px;   
    } 
  }
  .nav-contents {
    align-items: center;
  }
  .nav-menu {
    display: flex;
    padding-right: 32px;
  }
  .nav-menu-mobile-button {
    display: none;
  }
  .nav-option-logo {
    padding-top: 18px;
    padding-left: 30px;
    padding-bottom: 8px; 
    margin-bottom: 16px;   
  }


  


}
@media only screen and (min-width: 996px) {

}
@media only screen and (min-width: 1200px) {
  .logo {
    img {
      margin-left: -2px;
    }
  }

  .nav-option-logo {
    padding-left: 32px;
  }
}
