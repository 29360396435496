

.project-type-page-back {
  background-color: var(--blue-1);
  min-height: 720px;
  position: relative;
}



.project-type-section {
  position: relative;
}

.italics {
  font-style: italic;
}

.clients {
  font-size: 16px;
  line-height: 22px;
  color: white;
  
  letter-spacing: 0.02em;
  display: flex;
  // justify-content: center;
  margin-bottom: 6px;
  margin-right: 6px;
  overflow: hidden;  
  column-fill: auto
  }

.clients-inner{
  padding-top:0px;

  max-width: 486px;
  width: 100%;
  padding-left: 30px;
  padding-right: 24px;
  h3 {
    font-size: 24px;
    margin-top:22px;
    margin-bottom:4px;
  }
  h4 {
    margin-top:0;
    margin-bottom:24px;
  }
  padding-bottom:8px;
}

.single-project-description-short {
  h1 {
    font-family: 'IBM Plex Sans';
    font-weight: 600;
    font-size: 36px;
    color: #FFFFFF;
    letter-spacing: -0.6px;
    line-height: 44px;
    margin-top: 18px;
    margin-bottom: 18px;
  }
}
.description-project-type {
  .description {
    font-family: 'IBM Plex Sans';
    font-weight: 500;
    font-size: 16px;
    color: #FFFFFF;
    letter-spacing: 0.23px;
    line-height: 22px;
  }
  .description-continued{
  
    .read-more-link {
      font-family: 'IBM Plex Sans';
      font-weight: 600;
      font-size: 14px;
      color: #FFFFFF;
      letter-spacing: 1.22px;
      height: 40px;
      display: inline-flex;
      width: 100%;
      align-items: center;
      //justify-content: center;    
      text-decoration: none;
      cursor: pointer;
    }

  }
}


.main-image-project-type {
  background-color: var(--blue-0);
  object-fit: cover;
  width: 100%;
  height: 380px;
  transition: transform 0.3s ease-in-out;
  &:hover{
    img {
      outline:1px solid white; 
    }
  }

}
.project-card-wrap-wrap {
  margin-bottom: 18px;
}
.project-card-wrap{
  display: flex;
  flex-direction: column;



  &:hover {
    .main-image-project-type{
      outline: 1px solid white;
      
      transform: scale(1.1);
   
      

        
      
    }
  }
}
.card-img {
  display: flex;
  position: relative;
  width: 100%;
  overflow: hidden;
  transition: opacity 0.1s ease-in-out;


}


.justify-content-center {
  display: flex;
  justify-content: center;
  position: relative;
}

.max-width-container {
  width: 1387px;
  padding-left: 32px;
  padding-right: 32px;
  position: relative;
}

.description-testimonials {
  h2 {
    line-height: 26px;
    margin: 10px 0px 30px 00px;
    
  }
}

.description-project-type, .description-testimonials {
  padding-right: 18px;
  margin-left: 18px;
  position: relative;
}

@media only screen and (max-width: 768px) {
  .max-width-container {
    padding-left: 18px;
    padding-right: 18px;
  }
}

@media only screen and (min-width: 768px) {
  .featured-panel {
    margin-right: 24px;
    margin-left: 24px;
  }
  .main-image-project-type {
    height: 480px;
  }
  .description-project-type, .description-testimonials {
    padding-right: 24px;
    margin-left: 32px;
  
  }
}

.project-type-header {

  font-family: 'IBM Plex Sans';
  font-weight: 600;
  font-size: 36px;
  color: #FFFFFF;
  letter-spacing: -0.6px;
  line-height: 39px;
  margin-top: 16px;
  margin-bottom: 14px;
  
}


.description-project-type-inner, .description-testimonials-inner {
  font-family: 'IBM Plex Sans';
  font-weight: 500;
  font-size: 16px;
  color: white;
  letter-spacing: 0.23px;
  line-height: 22px;
  max-width: 737px;
}

.border-top {
  border-top: 1px solid var(--blue-3);
}

.project-type-zone-1{
  display: flex;
  flex-direction: column;
  position: relative;
}

.look-down-side {
  display: none;
}

.disappear-below-996 {
  display: none;
}

.clients-section {
  background-color: var(--blue-0);

}
.clients-header {
  padding-top: 24px;
  h1 {
    font-size: 44px;
  }
}

.client-type-title {
  margin-block-start: 0;
  margin-bottom:12px;
  font-family: 'IBM Plex Sans';
  font-weight: 500;
  font-size: 36px;
  line-height: 32px;
  color: white;
}

.client-type-title-blank {
  height: 0px;
}

@media only screen and (min-width: 996px) {
  .disappear-below-996 {
    display: block;
  }
  .disappear {
    display: none;
  }
  .description-overlay-project-type {
 
    position: absolute;

    top: 1px;
    bottom   : 1px;

    width: calc(100% - 24px);
    z-index: 1;
    pointer-events   : none;
    

    padding-bottom: 32px;
    padding-right: 20px;
    // min-width: 360px;
    // max-width: 420px;
    
    background: linear-gradient(0deg, var(--blue-1) 0%,  #466a8f00 9%), linear-gradient(180deg, var(--blue-1) 0.5%,  #466a8f00 5%);

  }

  .read-more-project-type {
    max-height: 44vh;
    overflow: scroll;
    border-bottom: 1px solid var(--blue-3);
    border-top: 1px solid var(--blue-3);

  }
  .look-down-side {
    margin-left: 40%;
    margin-right: auto;
    display: flex;

    position: relative;
    transform: scale(0.8);
    margin-top: 12px;
    margin-bottom: 24px;
  }

  .main-image-project-type{
    height: 340px;
  }

  .project-type-zone-1{
    position: relative;
    flex-direction: row;
    padding-bottom: 40px;
  }

  .featured-panel {
    // background-color: var(--blue-1);
    // padding: 6px;
    margin-bottom: 20px;
    width: 80vw;
    width: fill-available;
    // margin-right: 32px;
    // margin-top: 94px;


    margin-right: 20px;
    margin-top: 42px;
    margin-left: 26px;
    padding: 1px;
  }
  
  .header-and-desc {
    position: sticky;
    position: -webkit-sticky;
    top:var(--header-height);

    align-self: flex-start;
    
  }

  .description-project-type-inner, .description-testimonials-inner {
    max-width: 480px;
    min-width: 390px;
    width: 420px;
  }

  .description-project-type,   .description-testimonials  {
    position: relative;
    padding-left:calc((100vw - 1392px)/2);
    margin-left: 32px;
  }

  .client-type-title {
    margin-block-start: 14px;
  }
  .client-type-title-blank {
    height: 42px;
  }

}
.description {
  p {
    font-family: 'IBM Plex Sans';
    font-weight: 500;
    font-size: 16px;

  }
  a {
    color: white;
    text-decoration: underline;
    font-weight: 800;
  }
}



@media only screen and (min-width: 1200px) {
  .main-image-project-type {
    height: 230px;
  }
  .project-card-wrap {
    margin-right: 18px;
  }

}